.modal-title {
    margin: 25px 15px;
}

.toggle-btn {
    padding: 7px;
    -webkit-border-radius: 51px;
    -moz-border-radius: 51px;
    border-radius: 51px;
    background-color: #fff;
    -webkit-box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.3);
    box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.3);
}

.toggle-item {
    background-color: transparent;
    padding: 7px 14px;
    border-radius: 150px;
    color: grey;
    font-weight: 700;
    font-size: 15px;
    align-items: center;
    cursor: pointer;
}

.toggle-active {
    color: white;
    font-weight: 700;
    font-size: 15px;
    cursor: pointer;
}

.toggle-active.login {
    background-image: linear-gradient(45deg, #53b2fe, #065af3);
}

.toggle-active.register {
    background-image: linear-gradient(92deg, #f0772c, #f95776);
}

.google-auth-block {
    font-family: "Lato", sans-serif !important;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.google-auth-title {
    font-size: 12px;
    color: #9b9b9b !important;
    margin-bottom: 20px;
}

.google-auth-icon {
    height: 20px;
    margin-right: 10px;
}

.google-auth-button {
    font-family: "Lato", sans-serif !important;
    border-radius: 4px !important;
    cursor: pointer;
    display: flex;
    align-items: center;
    border: none;
    background-color: #fff !important;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
    font-size: 14px !important;
    color: #4a4a4a !important;
    font-weight: 700 !important;
    text-transform: initial !important;
}

.tc {
    margin-top: 25px;
    color: black !important;
}

.privacy {
    color: #065af3;
    text-decoration: underline;
}

/* ? Edit Register Form Styles */

.edit-register-form {
    align-items: center;
}

.MuiOutlinedInput-input {
    padding: 14px !important;
}

.form-btn {
    font-family: "Lato", sans-serif !important;
    text-transform: initial !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    color: white !important;
    width: 95% !important;
    margin: 15px 5px !important;
    padding: 8px !important;
    font-weight: 700 !important;
}

.form-btn.login {
    background-image: linear-gradient(45deg, #53b2fe, #065af3);
}

.form-btn.register {
    background-image: linear-gradient(92deg, #f0772c, #f95776);
}

/* ? Edit Register Form Styles */

.input-field {
    width: 95%;
    font-size: 8px !important;
    margin: 5px !important;
}

.Mui-focused fieldset {
    border-color: #53b2fe !important;
}

/* ? Modal Styles */

.auth-modal-head {
    font-family: "Lato", sans-serif !important;
    font-size: 26px;
    color: gray;
}
/* ? Modal Styles */

@media (max-width: 767px) {
    .auth-modal-head {
        font-size: 20px;
    }
}
