.App {
  background-color: #f3f2f3;
}

.paper-plane {
  position: absolute;
  z-index: 1;
  filter: grayscale(1);
  padding-top: 10%;
  padding-left: 25%;
  height: 90%;
  width: 75%;
}

@keyframes App-logo-spin {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@media (max-width: 800px) {
  .App {
    overflow: hidden;
  }
}

@media (prefers-reduced-motion: no-preference) {
  /* .App-logo {
    animation: App-logo-spin infinite 10s linear;
  } */
}
