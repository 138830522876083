.root-class {
    flex-grow: 1;
    font-family: "Lato", sans-serif !important;
}

.app-bar {
    background: transparent !important;
    padding-left: 17%;
    padding-right: 17%;
    margin-top: -5px;
}

.privacy {
    color: #065af3;
    text-decoration: underline;
}

.web-app-bar {
    display: flex !important;
}

.mobile-app-bar {
    display: none !important;
}

.unscrolled {
    box-shadow: none !important;
}

.scrolled {
    background: white !important;
    box-shadow: initial;
    animation: Header-Load 0.3s linear;
}

.header-logo {
    flex-grow: 1;
}

.header-logo img {
    height: 6vmin;
}

.auth-button {
    font-family: "Lato", sans-serif !important;
    height: 35px;
    text-transform: initial !important;
    background-image: linear-gradient(45deg, #53b2fe, #065af3);
    font-weight: 700 !important;
    font-size: 10px !important;
    color: white !important;
    padding: 5px 10px !important;
    margin-left: 5px !important;
}

.auth-btn-logo {
    height: 25px;
    filter: invert(1);
}

.info-button {
    height: 35px;
    cursor: pointer;
    background-color: rgb(43 57 72) !important;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2) !important;
    margin-left: 5px !important;
    padding: 5px !important;
    font-size: 10px !important;
    color: white !important;
}

.info-btn-logo {
    height: 25px;
    margin-left: 3px;
}

.info-btn-1 {
    width: 175px;
    font-size: 10px !important;
}

.info-btn-2 {
    width: 90px;
    text-transform: initial !important;
}

.info-btn-3 {
    width: 80px;
}

.info-btn-4 {
    width: 20px;
}

.info-btn-logo-4 {
    margin-right: -10px !important;
    height: 30px;
}

.info-btn-5 {
    width: 200px;
    font-size: 8px;
    text-transform: initial !important;
}

.info-btn-6 {
    width: 100px;
    padding-left: 10px !important;
}

.info-btn-logo-6 {
    height: 15px;
}

.material-icon {
    font-size: 25px !important;
    margin-left: 25px;
}

.material-icon-6 {
    font-size: 20px !important;
    margin-left: 0px;
}

.info-modal-head {
    font-family: "Lato", sans-serif !important;
    font-size: 28px;
    color: black;
    margin-top: 0;
}

.country-name {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.country-flag {
    height: 50px;
}

.partner-logo {
    height: 75px;
}

.all-country-flags {
    height: 250px;
}

.coupon-code {
    display: flex;
    text-align: left;
    align-items: left;
    justify-content: center;
    padding: 10px;
}

.copy-button {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    height: 40px;
    padding: 10px;
    cursor: pointer;
    background-image: linear-gradient(45deg, #53b2fe, #065af3);
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2) !important;
    font-size: 14px !important;
    color: white !important;
    margin-top: 15px !important;
}

.copied {
    background-image: linear-gradient(45deg, #24ff07, #00910c);
}

@keyframes Header-Load {
    0% {
        margin-top: -70px;
    }
    100% {
        margin-top: 0px;
    }
}

@media (max-width: 767px) {
    .web-app-bar {
        display: none !important;
    }
    .mobile-app-bar {
        display: flex !important;
        background: white;
        height: 65px !important;
    }
    .app-bar {
        padding: 0;
    }
    .auth-button {
        min-width: 125px !important;
    }
    .mobile-info-button {
        margin: 1% !important;
        padding: 1% !important;
        background: white !important;
    }
    .info-btn-logo-6 {
        height: 25px !important;
        margin-left: 10px;
    }
    .all-country-flags {
        height: 185px;
    }
    .root-class {
        position: fixed;
        z-index: 1;
    }
    .scrolled {
        animation: none;
        box-shadow: none !important;
    }
    .unscrolled {
        box-shadow: none !important;
    }
    .header-logo img {
        height: 13vmin;
    }
    .auth-modal-head {
        font-size: 20px;
    }
}

@media (min-width: 768px) and (max-width: 999px) {
    .web-app-bar {
        display: none !important;
    }
    .mobile-app-bar {
        display: flex !important;
        background: white;
        height: 65px !important;
    }
    .app-bar {
        padding: 0;
    }
    .auth-button {
        min-width: 125px !important;
    }
    .mobile-info-button {
        margin: 1% !important;
        padding: 1% !important;
        background: white !important;
    }
    .info-btn-logo-6 {
        height: 25px !important;
        margin-left: 10px;
    }
    .all-country-flags {
        height: 185px;
    }
    .root-class {
        position: fixed;
        z-index: 1;
    }
    .scrolled {
        animation: none;
        box-shadow: none !important;
    }
    .unscrolled {
        box-shadow: none !important;
    }
    .header-logo img {
        height: 7vmin;
    }
    .auth-modal-head {
        font-size: 20px;
    }
}

@media (min-width: 1525px) and (max-width: 1625px) {
    .info-button {
        font-size: 9px !important;
    }
    .auth-button {
        font-size: 9px !important;
    }
}

@media (min-width: 1301px) and (max-width: 1524px) {
    .info-button {
        font-size: 7px !important;
    }
    .auth-button {
        font-size: 8px !important;
    }
}

@media (min-width: 1000px) and (max-width: 1300px) {
    .info-button {
        display: none !important;
    }
    .auth-button {
        font-size: 8px !important;
    }
}
