.home-page {
    height: 1500px;
}

.top-div {
    height: 475px;
    background-image: linear-gradient(0deg, #144174, #042c5a, #06121f);
}

.mobile-options-area {
    display: none;
}

.booking-area {
    border-radius: 8px;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
    padding: 50px 20px 0;
    margin: 6.5% 18% 0%;
    position: absolute;
    width: 62%;
    height: 225px;
}

.options-area {
    border-radius: 8px;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
    padding: 50px 5px 0;
    margin: -7.5% 9% 0%;
    position: absolute;
    width: 78%;
    height: 30px;
    display: flex;
    flex-direction: row;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.option {
    margin-top: -5.5% !important;
    width: 10%;
    padding-bottom: 10px;
    margin-bottom: -5px !important;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: grey;
    font-size: 15px;
}

.mobile {
    width: 20%;
    margin-top: 0 !important;
}

.option-8 {
    font-size: 13px;
}

.option-img {
    height: 40px;
}

.option-name-icon {
    font-size: 15px !important;
    position: absolute;
    font-weight: 700;
    padding-top: 2px;
}

.option.active {
    color: #008cff;
    border-bottom: solid 3px #008cff;
    background: transparent;
    border-radius: 0;
}

.option-active {
    color: #008cff;
}

.config-row {
    font-family: "Lato", sans-serif !important;
    color: #4a4a4a !important;
    margin: 0 0px 10px 15px;
    padding-right: 15px;
    font-weight: 700;
    font-size: 14px;
}

.more-logo {
    height: 150px;
}

.flights-block {
    font-family: "Lato", sans-serif !important;
    display: block;
}

.mobile-flights-block {
    display: none;
}

.main-block-option {
    margin: 0 50px;
    justify-content: center;
    display: grid;
    font-size: 20px;
}

.main-block-option>b {
    justify-content: center;
    display: grid;
}

.MuiRadio-root {
    font-family: "Lato", sans-serif !important;
    padding: 3px !important;
}

.radio-btn.active>.MuiRadio-root {
    color: black !important;
}

.MuiTypography-body1 {
    font-family: "Lato", sans-serif !important;
    font-weight: 900 !important;
    font-size: 14px !important;
    color: #9b9b9b !important;
}

.radio-btn.active>.MuiTypography-body1 {
    color: black !important;
}

.radio-btn.active {
    background: #e7e7e7;
    padding-right: 5px;
    border-radius: 20px;
    font-weight: 700;
    color: black;
    text-transform: uppercase;
}

.select-input {
    border: solid 1px #e7e7e7;
    height: 100px;
    cursor: pointer;
}

.select-input:hover {
    background: #eaf5ff;
}

.select-1 {
    width: 26%;
    border-radius: 8px 0 0 8px;
}

.from-to {
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    margin-left: -22px;
    border-radius: 100%;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 1px 30px 0 rgba(0, 0, 0, 0.1);
    /* z-index: 1; */
    cursor: pointer;
    position: absolute;
    color: #008cff;
    margin-top: 30px;
    margin-left: 23.5%;
}

.select-2 {
    width: 26%;
}

.select-3 {
    width: 13%;
}

.select-4 {
    width: 13%;
}

.select-5 {
    width: 22%;
    border-radius: 0 8px 8px 0;
}

.mobile-flights-block>.select-input-row>.select-1 {
    width: 48%;
    margin: 1%;
    border-radius: 8px;
}

.mobile-flights-block>.select-input-row>.from-to {
    margin-top: 35px;
    margin-left: 45%;
}

.mobile-flights-block>.select-input-row>.select-2 {
    width: 48%;
    margin: 1%;
    border-radius: 8px;
}

.mobile-flights-block>.select-input-row>.select-3 {
    width: 31%;
    margin: 1%;
    border-radius: 8px;
}

.mobile-flights-block>.select-input-row>.select-4 {
    width: 31%;
    margin: 1%;
    border-radius: 8px;
}

.mobile-flights-block>.select-input-row>.select-5 {
    width: 31%;
    margin: 1%;
    border-radius: 8px;
}

.mobile-flights-block>.select-input-row>.MuiAutocomplete-inputRoot {
    padding: 25px 9px 0 9px !important;
    height: 25px;
    width: 98% !important;
}

.mobile-flights-block>.MuiInputAdornment-root>.MuiButtonBase-root.MuiIconButton-root {
    padding: 40px 50px 35px 50px !important;
}

.mobile-flights-block>.config-row {
    margin: 15px 0px 10px 0px;
    justify-content: center;
}

.mobile-radio-btn {
    justify-content: center;
    display: flex;
}

.from-loc {
    font-family: "Lato", sans-serif !important;
    padding: 5px 0 0 25px;
    display: block;
    color: #4a4a4a;
    font-size: 14px;
    font-weight: 700;
}

.airport-dets {
    color: #4a4a4a;
    font-weight: 500;
    padding: 0 0 5px 25px;
}

.trending-search {
    width: 100%;
    display: flex;
    margin-top: 10px;
    position: absolute;
    align-items: left;
    justify-content: left;
    margin-top: 1.5% !important;
}

.trend-seperation {
    border: 1px solid #82828263;
    margin: 5px 25px;
}

.fare-btn {
    font-family: "Lato", sans-serif !important;
    border-radius: 4px !important;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2) !important;
    background-color: #ffffff !important;
    padding: 8px 12px !important;
    text-transform: uppercase !important;
    margin-right: 10px !important;
    cursor: pointer !important;
    font-size: 11px !important;
    font-weight: 700 !important;
}

.fare-btn.active {
    background: #008cff !important;
    color: #fff !important;
}

.class-btn {
    margin: 0 !important;
}

.class-btn.active {
    padding: 14px 12px !important;
}

.slider-val {
    font-size: 25px;
    font-weight: 900;
    color: black;
    margin-left: 25px;
}

.popover-span {
    display: block;
    margin: 25px 0;
}

.trend {
    font-size: 12px;
    font-weight: 600;
    margin: 10px;
}

.search {
    color: #4a4a4a;
    font-weight: 500;
    padding: 8px 10px;
    border-radius: 4px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
    background: #f2f2f2;
    cursor: pointer;
    margin-top: 0;
}

.search-arrow {
    color: #008cff;
    font-size: 13px;
    font-weight: 800;
    display: inline-block;
    width: 25px;
}

.search-icon {
    position: absolute !important;
    margin-top: -16px !important;
}

.search-btn {
    font-family: "Lato", sans-serif !important;
    background-image: linear-gradient(45deg, #53b2fe, #065af3);
    text-transform: initial !important;
    font-weight: 700 !important;
    font-size: 24px !important;
    color: white !important;
    border-radius: 34px !important;
    font-weight: 700 !important;
    width: 216px;
    padding: 0px !important;
    margin-top: 65px !important;
    margin-left: 40% !important;
    position: absolute !important;
}


/* ? Autocomplete Input Field Styles Override */

.MuiAutocomplete-inputRoot>.MuiOutlinedInput-notchedOutline {
    border: transparent !important;
}

.MuiAutocomplete-inputRoot>.MuiOutlinedInput-notchedOutline:focus {
    border: transparent !important;
}

.MuiAutocomplete-inputRoot {
    padding: 0 9px 0 9px !important;
    height: 50px;
    width: 98.5% !important;
}

.MuiAutocomplete-inputRoot .MuiAutocomplete-input {
    font-family: "Lato", sans-serif !important;
    min-width: 100%;
    padding: 25px 0 25px 15px !important;
    font-size: 30px;
    font-weight: 900;
    height: 100%;
    margin-top: -25px;
    cursor: pointer;
}


/* ? Autocomplete Input Field Styles Override */


/* ? Datepicker internal styles Override */

.MuiPopover-paper {
    margin-left: 155px !important;
    margin-top: -50px !important;
}

.mobile-menu>.MuiPopover-paper {
    margin: 10% 0 0 0% !important;
    min-width: initial !important;
    max-width: initial !important;
}

.MuiInputAdornment-root>.MuiButtonBase-root.MuiIconButton-root {
    padding: 40px 70px 35px 60px !important;
    margin-top: -155px !important;
    margin-left: -8px !important;
    opacity: 0 !important;
}

.empty-date>.MuiInputBase-root>.MuiInputAdornment-root>.MuiButtonBase-root.MuiIconButton-root {
    margin-top: -30px !important;
}

.MuiInput-underline:before {
    border: none !important;
}

.MuiInput-underline:after {
    border: none !important;
}

#mobile-fromDate.MuiInputBase-input {
    color: transparent !important;
}

#fromDate.MuiInputBase-input {
    color: transparent !important;
}

#mobile-toDate.MuiInputBase-input {
    color: transparent !important;
}

#toDate.MuiInputBase-input {
    color: transparent !important;
}


/* ? Datepicker internal styles Override */


/* ? Popover internal styles Override */

.select-travellers>.MuiPopover-paper {
    margin: 0% !important;
    min-width: 450px !important;
    min-height: 250px !important;
    padding: 1% 2%;
}


/* ? Popover internal styles Override */

.date-title {
    color: #4a4a4a;
    font-weight: 700;
    font-size: 14px;
    margin: 10px;
    margin-bottom: 5px;
}

.day-title {
    margin-top: 5px;
    font-weight: 500;
    text-transform: capitalize;
}

.date-icon {
    margin-top: -3px !important;
    position: absolute;
    font-size: 22px !important;
    color: #008cff !important;
    font-weight: 700 !important;
}

.cancel-icon {
    position: absolute;
    margin-top: -2%;
    margin-left: 10%;
    font-size: 22px !important;
    color: grey;
    z-index: 1;
}

.day {
    font-size: 30px;
    font-weight: 900;
    margin-left: 10px;
    color: black;
}

.month {
    font-size: 22px;
    font-weight: 500;
    color: black;
}

.year {
    font-size: 23px;
    font-weight: 500;
    color: black;
}

@media (max-width: 767px) {
    .top-div {
        height: 600px;
    }
    .booking-area {
        margin: 40% 0% 0%;
        padding: 0;
        width: 100%;
        height: 50%;
    }
    .flights-block {
        display: none;
    }
    .mobile-flights-block {
        display: block;
    }
    .options-area {
        display: none;
    }
    .mobile-options-area {
        display: block;
        position: absolute;
        margin-top: 59px;
        height: 75px;
        width: 100% !important;
        box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
        background-color: #fff;
        position: fixed;
        z-index: 1;
        box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 10px 10px 0px rgba(0, 0, 0, 0.12);
    }
    .cancel-icon {
        display: none;
    }
    .date-title {
        font-size: 9px;
        margin-bottom: 0px;
    }
    .mobile-date {
        font-size: 12px;
    }
    .travel-type-title {
        font-size: 7px;
        margin-left: 5px;
    }
    .MuiInputAdornment-root>.MuiButtonBase-root.MuiIconButton-root {
        padding: 40px 50px 35px 50px !important;
        margin-top: -120px !important;
        margin-left: -8px !important;
        opacity: 0 !important;
    }
    .select-travellers>.MuiPopover-paper {
        margin: 0% 0 0 -5% !important;
        min-width: 325px !important;
        max-width: 325px !important;
        min-height: 250px !important;
        padding: 1% 2%;
    }
    .adult-num {
        font-size: 25px;
    }
    .adult {
        font-size: 15px;
    }
    .MuiPopover-paper {
        margin-left: 50px !important;
        margin-top: -25px !important;
    }
    .airport-dets {
        font-size: 11px !important;
        margin-top: -5px !important;
    }
    .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
        padding: 25px 0 25px 8px !important;
        font-size: 20px;
    }
    .search-btn {
        margin-top: 35px !important;
        margin-left: 23% !important;
    }
}

@media (min-width: 767px) and (max-width: 999px) {
    .top-div {
        height: 700px;
    }
    .booking-area {
        margin: 20% 0% 0%;
        padding: 0;
        width: 100%;
        height: 50%;
    }
    .flights-block {
        display: none;
    }
    .mobile-flights-block {
        display: block;
    }
    .options-area {
        display: none;
    }
    .mobile-options-area {
        display: block;
        position: absolute;
        margin-top: 59px;
        height: 75px;
        width: 100% !important;
        box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
        background-color: #fff;
        position: fixed;
        z-index: 1;
        box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 10px 10px 0px rgba(0, 0, 0, 0.12);
    }
    .cancel-icon {
        display: none;
    }
    .date-title {
        font-size: 9px;
        margin-bottom: 0px;
    }
    .mobile-date {
        font-size: 12px;
    }
    .travel-type-title {
        font-size: 7px;
        margin-left: 5px;
    }
    .select-travellers>.MuiPopover-paper {
        margin: 0% 0 0 -5% !important;
        min-width: 325px !important;
        max-width: 325px !important;
        min-height: 250px !important;
        padding: 1% 2%;
    }
    .MuiPopover-paper {
        margin-left: 50px !important;
        margin-top: -25px !important;
    }
    .MuiInputAdornment-root>.MuiButtonBase-root.MuiIconButton-root {
        padding: 40px 50px 35px 50px !important;
        margin-top: -120px !important;
        margin-left: -8px !important;
        opacity: 0 !important;
    }
    .airport-dets {
        font-size: 11px !important;
        margin-top: -5px !important;
    }
    .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
        padding: 25px 0 25px 8px !important;
        font-size: 20px;
    }
    .search-btn {
        margin-top: 35px !important;
        margin-left: 23% !important;
    }
}

@media (min-width: 1000px) and (max-width: 1400px) {
    .options-area {
        padding: 30px 5px 0 !important;
        margin: -9.5% 9% 0% !important;
    }
    .option {
        padding-bottom: 0px !important;
        font-size: 11px;
    }
    .option-8 {
        font-size: 9px;
    }
    .trending-search {
        display: none;
    }
    .MuiInputAdornment-root>.MuiButtonBase-root.MuiIconButton-root {
        padding: 40px 30px 35px 26px !important;
        margin-top: -215px !important;
        margin-left: -8px !important;
        opacity: 0 !important;
    }
    .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
        font-size: 15px;
    }
    .from-to {
        width: 30px;
        height: 30px;
        margin-left: 22.5%;
    }
    .date-title {
        font-size: 9px;
    }
    .day {
        font-size: 18px;
    }
    .month {
        font-size: 16px;
    }
    .year {
        font-size: 16px;
    }
    .cancel-icon {
        display: none;
    }
}

@media (min-width: 1401px) and (max-width: 1650px) {
    .options-area {
        padding: 42px 5px 0;
    }
    .option {
        padding-bottom: 0px;
        font-size: 13px;
    }
}

@media (min-width: 1301px) and (max-width: 1650px) {
    .booking-area {
        margin: 6.5% 15% 0%;
        width: 75%;
    }
    .options-area {
        margin-top: -7.5% !important;
    }
    .search-btn {
        margin-left: 38% !important;
    }
}

@media (min-width: 1000px) and (max-width: 1300px) {
    .search-btn {
        margin-top: 35px !important;
        margin-left: 33% !important;
    }
}

@media (max-height: 568px) {
    .booking-area {
        margin-top: 50%;
        height: 60%;
    }
    .search-btn {
        margin-left: 17% !important;
    }
}